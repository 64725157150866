// All of the Agility Page Module Components that are in use in this site need to be imported into this index file.
// Place Page Modules in allModules array below, passing in a name and the component.
import loadable from "@loadable/component"
const PostsListing = loadable(() => import("./PostsListing"))

const allModules: any[] = [
  { name: "PostsListing", module: PostsListing },  
]

export const getModule = (moduleName: string) => {
  if (!moduleName) return null
  const obj = allModules.find(
    m => m.name.toLowerCase() === moduleName.toLowerCase()
  )
  if (!obj) return null
  return obj.module
}
