import React from "react"
import { SiteContextProvider } from "./src/custom/SiteContextProvider"
import { SearchContextProvider } from "@organic-return/foundation-react/src/components/Search/SearchContext"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

import "./src/styles/example-theme.css"

let handleResizeTimeout = null
const handleResize = () => {
  const rootElement = document.documentElement
  const headerHeight = document.querySelector("header")?.clientHeight
  rootElement?.style.setProperty("--header-height", `${headerHeight}px`)
}
export const onRouteUpdate = () => {
  clearTimeout(handleResizeTimeout)
  handleResizeTimeout = setTimeout(() => {
    handleResize()
  }, 750)
}
export const onInitialClientRender = () => {
  window.addEventListener("resize", handleResize)
}

export const wrapRootElement = ({ element }) => (
  <SiteContextProvider>
    <SearchContextProvider>
      <GoogleReCaptchaProvider reCaptchaKey={process.env.GOOGLE_RECAPTCHA_SITE_KEY} scriptProps={{ async: true, defer: true }}>
        {element}
      </GoogleReCaptchaProvider>
    </SearchContextProvider>
  </SiteContextProvider>
)
