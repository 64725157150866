import { SiteContext } from "@organic-return/foundation-gatsby-plugin-agility/src/components/common/SiteContext"
import { IListing, IListingSummary } from "@organic-return/foundation-react/src/lib/Listing"
import { Sort } from "@organic-return/foundation-react/src/components/Search/Sort"
import React from "react"
import { getModule } from "../components/agility-pageModules"
import {
  Check,
  Choose,
  Text,
  DateRange,
} from "@organic-return/foundation-react/src/components/Search/Filter"
import { getPageTemplate } from "../components/agility-pageTemplates"
import { EListingVitalsLayout } from "@organic-return/foundation-react/src/components/Listing/ListingVitals"
import { EListingDetailsLayout } from "@organic-return/foundation-react/src/components/Listing/ListingDetails"
import { graphql, navigate, useStaticQuery } from "gatsby"
import { ESellFormLayout } from "@organic-return/foundation-react/src/components/Forms/SellForm"
import { ISearchContext } from "@organic-return/foundation-react/src/components/Search/SearchContext"
import { EContactCardLayout } from "@organic-return/foundation-react/src/components/Contact"
import { FooterLabeledColumns } from "@organic-return/foundation-react/src/components/Footer/FooterLabeledColumns"
import { IAgent } from "@organic-return/foundation-react/src/lib"
import { CommunityLayoutHeroBox } from "@organic-return/foundation-react/src/components/Community/CommunityLayoutHeroBox"
import { EContactFormLayout } from "@organic-return/foundation-react/src/components/Forms"
import { Count } from "@organic-return/foundation-react/src/components/Search"
import { EDisclaimerLocation } from "@organic-return/foundation-react/src/components/Listing/util"

export const SiteContextProvider: React.FC = ({ children }: any) => {
  const data = useStaticQuery(graphql`
    query SiteContextProviderQuery {
      config: agilitySiteConfiguration {
        customFields {
          rentalSearchPath
          listingSearchPath
          listingLayoutHeadingImage {
            url
          }
          footerCopyright 
        }
        columnsTitles
      }
      offices: allOffice {
        nodes {
          mlsID
          fullName
          address {
            street1
            street2
            city
            state
            postal
          }
          phone
          fax
          email
        }
      }  
      allPerson(sort: {fields: first, order: ASC}) {
        nodes {
          fullName
        }
      }
      feedDate {
        timestamp
      }  
    }
  `)
  const selectStyles = {
    control: (base: object) => ({
      ...base,
      borderColor: "transparent",
      background: "transparent",
      borderBottomColor: "var(--color-composite-text)",
      borderRadius: 0,
    }),
    option: (base: object) => ({
      ...base,
      color: "var(--color-body-text)",
    }),
    singleValue: (base: any) => ({
      ...base,
      color: "var(--color-composite-text)",
      textTransform: "uppercase",
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      paddingRight: "0px",
      color: "var(--color-composite-text)",
    }),
    indicatorSeparator: (base: object) => ({
      ...base,
      display: "none",
    }),
    placeholder: (base: object) => ({
      ...base,
      color: "var(--color-composite-text)",
      whiteSpace: "nowrap",
      textTransform: "uppercase",
    }),
    valueContainer: (base: object) => ({
      ...base,
      paddingLeft: 0,
      textTransform: "uppercase",
    }),
  }
  const priceFilters = function (context: ISearchContext) {
    return (
      <>
        <Choose
          context={context}
          label="Min Price"
          placeholder="Min Price"
          customFilter={(arg, value) => {
            let argl = arg as IListingSummary
            let vstr = value as string
            return (argl.price || 0) >= parseInt(vstr) || false
          }}
          param="minPrice"
          resolver={arg => arg.price}
          reactSelectStyles={selectStyles}
          options={[
            { label: "> $200k", value: "200000" },
            { label: "> $400k", value: "400000" },
            { label: "> $600k", value: "600000" },
            { label: "> $800k", value: "800000" },
            { label: "> $1M", value: "1000000" },
            { label: "> $3M", value: "3000000" },
            { label: "> $5M", value: "5000000" },
            { label: "> $7M", value: "7000000" },
            { label: "> $10M", value: "10000000" },
            { label: "> $15M", value: "15000000" },
          ]}
        />
        <Choose
          context={context}
          label="Max Price"
          placeholder="Max Price"
          customFilter={(arg, value) => {
            let argl = arg as IListingSummary
            let vstr = value as string
            return (argl.price || 0) <= parseInt(vstr) || false
          }}
          param="maxPrice"
          resolver={arg => arg.price}
          reactSelectStyles={selectStyles}
          options={[
            { label: "< $200k", value: "200000" },
            { label: "< $400k", value: "400000" },
            { label: "< $600k", value: "600000" },
            { label: "< $800k", value: "800000" },
            { label: "< $1M", value: "1000000" },
            { label: "< $3M", value: "3000000" },
            { label: "< $5M", value: "5000000" },
            { label: "< $7M", value: "7000000" },
            { label: "< $10M", value: "10000000" },
            { label: "< $15M", value: "15000000" },
          ]}
        />
      </>
    )
  }

  const rentalPriceFilters = function (context: ISearchContext) {
    return (
      <>
        <Choose
          context={context}
          label="Min Price"
          placeholder="Min Price"
          customFilter={(arg, value) => {
            let argl = arg as IListingSummary
            let vstr = value as string
            return (argl.priceLow || 0) >= parseInt(vstr) || false
          }}
          param="minPrice"
          resolver={arg => arg.priceLow}
          reactSelectStyles={selectStyles}
          options={[
            { label: "> $1k", value: "1000" },
            { label: "> $2k", value: "2000" },
            { label: "> $3k", value: "3000" },
            { label: "> $5k", value: "5000" },
            { label: "> $7k", value: "7000" },
            { label: "> $10k", value: "10000" },
            { label: "> $15k", value: "15000" },
            { label: "> $20k", value: "20000" },
            { label: "> $30k", value: "30000" },
            { label: "> $40k", value: "40000" },
          ]}
        />
        <Choose
          context={context}
          label="Max Price"
          placeholder="Max Price"
          customFilter={(arg, value) => {
            let argl = arg as IListingSummary
            let vstr = value as string
            return (argl.priceLow || 0) <= parseInt(vstr) || false
          }}
          param="maxPrice"
          resolver={arg => arg.priceLow}
          reactSelectStyles={selectStyles}
          options={[
            { label: "< $1k", value: "1000" },
            { label: "< $2k", value: "2000" },
            { label: "< $3k", value: "3000" },
            { label: "< $5k", value: "5000" },
            { label: "< $7k", value: "7000" },
            { label: "< $10k", value: "10000" },
            { label: "< $15k", value: "15000" },
            { label: "< $20k", value: "20000" },
            { label: "< $30k", value: "30000" },
            { label: "< $40k", value: "40000" },
          ]}
        />
      </>
    )
  }

  const bedroomsFilter = function (context: ISearchContext) {
    return (
      <>
        <Choose
          context={context}
          label="Beds"
          placeholder="Beds"
          customFilter={(arg, value) => {
            let argl = arg as IListingSummary
            let vstr = value as string
            return (argl.beds || 0) >= parseInt(vstr) || false
          }}
          param="beds"
          resolver={arg => arg.beds}
          reactSelectStyles={selectStyles}
          options={[
            { label: "0+", value: "0" },
            { label: "1+", value: "1" },
            { label: "2+", value: "2" },
            { label: "3+", value: "3" },
            { label: "4+", value: "4" },
            { label: "5+", value: "5" },
            { label: "6+", value: "6" },
            { label: "7+", value: "7" },
            { label: "8+", value: "8" },
          ]}
        />
      </>
    )
  }

  const bathroomsFilter = function (context: ISearchContext) {
    return (
      <>
        <Choose
          context={context}
          label="Baths"
          placeholder="Baths"
          customFilter={(arg, value) => {
            let argl = arg as IListingSummary
            let vstr = value as string
            return (argl.baths || 0) >= parseInt(vstr) || false
          }}
          param="baths"
          resolver={arg => arg.baths}
          reactSelectStyles={selectStyles}
          options={[
            { label: "0+", value: "0" },
            { label: "1+", value: "1" },
            { label: "2+", value: "2" },
            { label: "3+", value: "3" },
            { label: "4+", value: "4" },
            { label: "5+", value: "5" },
            { label: "6+", value: "6" },
            { label: "7+", value: "7" },
            { label: "8+", value: "8" },
          ]}
        />
      </>
    )
  }

  const offices = data.offices?.nodes &&
    data.offices.nodes.map((office: any) => {
      return {
        value: office.fullName,
        label: office.fullName
      }
    })

  let officeDictionary: any = {}
  data.offices?.nodes &&
    data.offices.nodes.forEach((office: any) => {
      officeDictionary[office.fullName] = office.mlsID
    })

  const allAgents = data.allPerson &&
    data.allPerson.nodes.map((agent: any) => {
      return agent.fullName
    })

  /**
   * Standard filters for global Real Estate listing search.
   */
  const listingStandardFilters = function (context: ISearchContext) {
    return (
      <>
        <Text
          context={context}
          placeholder="CONDOMINIUM, CITY, NEIGHBORHOOD, ADDRESS, ZIP OR MLS#"
          param="text"
          label="CONDOMINIUM, CITY, NEIGHBORHOOD, ADDRESS, ZIP OR MLS#"
          inputClassName="w-full bg-transparent text-composite-text border-composite-text placeholder:text-inherit"
          className="col-span-3"
          resolver={(arg: any, text) => {
            let argl = arg as IListingSummary
            let match = text.toLowerCase()
            return (argl.mlsID && argl.mlsID.toLowerCase().includes(match)) ||
              (argl.address.text &&
                argl.address.text.toLowerCase().includes(match)) ||
              (argl.majorArea && argl.majorArea.toLowerCase().includes(match)) ||
              (argl.minorArea && argl.minorArea.toLowerCase().includes(match)) ||
              (argl.subdivision &&
                argl.subdivision.toLowerCase().includes(match)) ||
              (argl.neighborhood &&
                argl.neighborhood.toLowerCase().includes(match))
              ? true
              : false
          }}
        />
      </>
    )
  }

  /**
   * Standard filters for global Real Estate listing search.
   */
  const listingEntryFilters = function (context: ISearchContext) {
    return (
      <Text
        context={context}
        placeholder="CONDOMINIUM, CITY, NEIGHBORHOOD, ADDRESS, ZIP OR MLS#"
        param="text"
        label="CONDOMINIUM, CITY, NEIGHBORHOOD, ADDRESS, ZIP OR MLS#"
        inputClassName="w-full bg-transparent text-composite-text border-composite-text placeholder:text-inherit text-xs"
        className="col-span-4"
        resolver={(arg: any, text) => {
          let argl = arg as IListingSummary
          let match = text.toLowerCase()
          return (argl.mlsID && argl.mlsID.toLowerCase().includes(match)) ||
            (argl.address.text &&
              argl.address.text.toLowerCase().includes(match)) ||
            (argl.majorArea && argl.majorArea.toLowerCase().includes(match)) ||
            (argl.minorArea && argl.minorArea.toLowerCase().includes(match)) ||
            (argl.subdivision &&
              argl.subdivision.toLowerCase().includes(match)) ||
            (argl.neighborhood &&
              argl.neighborhood.toLowerCase().includes(match))
            ? true
            : false
        }}
      />
    )
  }
  /**
   * Standard filters for community page Real Estate listing search.
   */
  const communityStandardFilters = function (context: ISearchContext) {
    return (
      <>
        <Choose
          context={context}
          placeholder="Type"
          label="Type"
          param="type"
          resolver={arg => arg.subType}
          sort="count"
          reactSelectStyles={selectStyles}
          match="any"
        />
        {priceFilters(context)}
        <Choose
          context={context}
          placeholder="Features"
          label="Features"
          param="features"
          resolver={arg => arg.features}
          sort="count"
          match="all"
          reactSelectStyles={selectStyles}
        />
      </>
    )
  }

  const communityListingEntryFilters = function (context: ISearchContext) {
    return (
      <>
        <Text
          context={context}
          placeholder="CONDOMINIUM, CITY, NEIGHBORHOOD, ADDRESS, ZIP OR MLS#"
          param="text"
          label="CONDOMINIUM, CITY, NEIGHBORHOOD, ADDRESS, ZIP OR MLS#"
          inputClassName="w-full bg-transparent text-composite-text border-composite-text placeholder:text-inherit"
          className="col-span-4"
          resolver={(arg: any, text) => {
            let argl = arg as IListingSummary
            let match = text.toLowerCase()
            return (argl.mlsID && argl.mlsID.toLowerCase().includes(match)) ||
              (argl.address.text &&
                argl.address.text.toLowerCase().includes(match)) ||
              (argl.majorArea && argl.majorArea.toLowerCase().includes(match)) ||
              (argl.minorArea && argl.minorArea.toLowerCase().includes(match)) ||
              (argl.subdivision &&
                argl.subdivision.toLowerCase().includes(match)) ||
              (argl.neighborhood &&
                argl.neighborhood.toLowerCase().includes(match))
              ? true
              : false
          }}
        />
      </>
    )
  }

  /**
   * Sorts to be used in all Listing (real estate) searches
   */
  const listingSort = function (context: ISearchContext) {
    return (
      <Sort
        context={context}
        className="text-center py-1"
        label="Sort by: "
        sorts={[
          {
            label: "Price: $$$ to $",
            key: "priceDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l1.price || 0) - (l0.price || 0)
            },
          },
          {
            label: "Price: $ to $$$",
            key: "priceASC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l0.price || 0) - (l1.price || 0)
            },
          },
          {
            label: "Most Recently Listed",
            key: "activeDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              let d0 = l0.onMktTS ? new Date(l0.onMktTS).getTime() : 0
              let d1 = l1.onMktTS ? new Date(l1.onMktTS).getTime() : 0
              return (
                d0 === d1 ? 0 : d0 < d1 ? 1 : -1
              )
            },
          },
          {
            label: "Bedrooms: High to Low",
            key: "bedsDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l1.beds || 0) - (l0.beds || 0)
            },
          },
          {
            label: "Bedrooms: Low to High",
            key: "bedsASC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l0.beds || 0) - (l1.beds || 0)
            },
          },
          {
            label: "Sq Ft: High to Low",
            key: "sqftDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l1.sqft || 0) - (l0.sqft || 0)
            },
          },
          {
            label: "Sq Ft: Low to High",
            key: "sqftASC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l0.sqft || 0) - (l1.sqft || 0)
            },
          },
          {
            label: "Lot Size: High to Low",
            key: "lotDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l1.lotacres || 0) - (l0.lotacres || 0)
            },
          },
          {
            label: "Lot Size: Low to High",
            key: "lotASC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l0.lotacres || 0) - (l1.lotacres || 0)
            },
          },
          {
            label: "Last Updated",
            key: "statusTSDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l1.statusTS || "").localeCompare(l0.statusTS || "")
            },
          },
        ]}
      />
    )
  }
  /**
   * Middle filter for real estate listing search
   */
  const listingMiddleFilters = function (context: ISearchContext) {
    return (
      <>
        <Choose
          className="w-full md:w-1/2 lg:w-1/4 px-2 mb-3 md:my-6 lg:my-14"
          context={context}
          placeholder="Location"
          label="Location"
          param="city"
          resolver={arg => arg.address.city}
          match="any"
          reactSelectStyles={selectStyles}
        />
        <Choose
          className="w-full  md:w-1/2 lg:w-1/4 px-2 mb-3 md:my-6 lg:my-14"
          context={context}
          placeholder="Type"
          label="Type"
          param="subType"
          resolver={arg => arg.subType}
          sort="count"
          match="one"
          reactSelectStyles={selectStyles}
        />
        <div className="w-full md:w-1/2 lg:w-1/4 px-2 mb-3 md:mb-6 lg:my-14 grid grid-cols-2 gap-4">
          {priceFilters(context)}
        </div>
        <Text
          className="w-full md:w-1/2 lg:w-1/4 px-2 mb-3 md:mb-6 lg:my-14"
          context={context}
          placeholder="MLS#, City, Zip, Area"
          param="text"
          label="MLS#, City, Zip, Area"
          inputClassName="w-full bg-transparent text-composite-text border-composite-text placeholder:text-inherit"
          resolver={(arg: any, text) => {
            let argl = arg as IListingSummary
            let match = text.toLowerCase()
            return (argl.mlsID && argl.mlsID.toLowerCase().includes(match)) ||
              (argl.address.text &&
                argl.address.text.toLowerCase().includes(match)) ||
              (argl.majorArea && argl.majorArea.toLowerCase().includes(match)) ||
              (argl.minorArea && argl.minorArea.toLowerCase().includes(match)) ||
              (argl.subdivision &&
                argl.subdivision.toLowerCase().includes(match)) ||
              (argl.neighborhood &&
                argl.neighborhood.toLowerCase().includes(match))
              ? true
              : false
          }}
        />
        <Choose
          className="w-full md:w-1/2 lg:w-1/4 px-2 mb-3 md:mb-6 lg:mb-14"
          context={context}
          placeholder="Office"
          label="Office"
          param="office"
          resolver={arg => arg.office?.[0]?.fullName}
          options={offices}
          sort="alpha"
          match="any"
          reactSelectStyles={selectStyles}
          customFilter={(arg, value) => {
            let argl = arg as any
            let vstr = value as string
            const officeMlsId = officeDictionary[vstr]
            return argl.office?.[0]?.mlsID && argl.office[0].mlsID == officeMlsId
          }}
        />
        <Choose
          className="w-full md:w-1/2 lg:w-1/4 px-2 mb-3 md:mb-6 lg:mb-14"
          context={context}
          placeholder="Area"
          label="Area"
          param="majorArea"
          resolver={arg => arg.majorArea}
          match="any"
          reactSelectStyles={selectStyles}
        />
        <div className="w-full md:w-1/2 lg:w-1/4 px-2 mb-3 md:mb-6 lg:mb-14 grid grid-cols-2 gap-4">
          {bedroomsFilter(context)}
          {bathroomsFilter(context)}
        </div>
        <Choose
          className="w-full md:w-1/2 lg:w-1/4 px-2 mb-3 md:mb-6 lg:mb-14"
          context={context}
          placeholder="Style"
          label="Style"
          param="style"
          resolver={arg => arg.propertyStyles}
          match="any"
          sort="alpha"
          reactSelectStyles={selectStyles}
        />
        <Choose
          className="w-full md:w-1/2 lg:w-1/4 px-2 mb-3 md:mb-6 lg:mb-14"
          context={context}
          label="Square Feet"
          placeholder="Square Feet"
          customFilter={(arg, value) => {
            let argl = arg as IListingSummary
            let vstr = value as string
            if (vstr === "0") {
              return (argl.sqft || 0) < 100
            }
            else {
              return (argl.sqft || 0) >= parseInt(vstr) || false
            }
          }}
          param="sqft"
          resolver={arg => arg.sqft}
          reactSelectStyles={selectStyles}
          options={[
            { label: "<1000", value: "0" },
            { label: "1000+", value: "1000" },
            { label: "1500+", value: "1500" },
            { label: "2000+", value: "2000" },
            { label: "2500+", value: "2500" },
          ]}
        />
        <Choose
          className="w-full md:w-1/2 lg:w-1/4 px-2 mb-3 md:mb-6 lg:mb-14"
          context={context}
          placeholder="Garage"
          label="Garage"
          param="garage"
          resolver={arg => arg.garageSpaces}
          match="one"
          reactSelectStyles={selectStyles}
          customFilter={(arg, value) => {
            let argl = arg as IListing
            let vstr = value as string
            return (argl.garageSpaces || 0) >= parseInt(vstr) || false
          }}
          options={[
            { label: "0+", value: "0" },
            { label: "1+", value: "1" },
            { label: "2+", value: "2" },
            { label: "3+", value: "3" },
            { label: "4+", value: "4" },
            { label: "5+", value: "5" },
            { label: "6+", value: "6" },
          ]}
        />
        <div className="w-full md:w-1/2 lg:w-1/4 px-2 mb-3 md:mb-6 lg:mb-14 grid grid-cols-2 gap-4">
          <Choose
            context={context}
            placeholder="Acres"
            label="Acres"
            param="lotacres"
            resolver={arg => arg.lotacres}
            match="one"
            reactSelectStyles={selectStyles}
            customFilter={(arg, value) => {
              let argl = arg as IListingSummary
              let vstr = value as string
              if (vstr === "0") {
                return (argl.lotacres || 0) < 1
              }
              else {
                return (argl.lotacres || 0) >= parseInt(vstr) || false
              }
            }}
            options={[
              { label: "<1", value: "0" },
              { label: "1+", value: "1" },
              { label: "5+", value: "5" },
              { label: "10+", value: "10" },
            ]}
          />
          <Choose
            context={context}
            placeholder="Lifestyle"
            label="Lifestyle"
            param="lifestyle"
            resolver={arg => arg.lifestyles}
            match="any"
            reactSelectStyles={selectStyles}
          />
        </div>
        <Choose
          className="w-full md:w-1/2 lg:w-1/4 px-2 mb-3 md:mb-6 lg:mb-14"
          context={context}
          placeholder="Amenities"
          label="Amenities"
          param="amenities"
          resolver={arg => arg.amenities}
          match="any"
          reactSelectStyles={selectStyles}
        />
        <Check
          context={context}
          label="Has Virtual Tour"
          inputClassName="bg-transparent"
          param="tour"
          className="uppercase text-composite-text w-full md:w-1/2 lg:w-1/4 px-2 mb-3 md:mb-6 lg:mb-14"
          resolver={(arg: any) => {
            let argl = arg as any
            return (argl.hasVirtualTour || false)
          }}
        />
        <Check
          context={context}
          label="Exclude Under Contract"
          inputClassName="bg-transparent"
          param="ours"
          className="uppercase text-composite-text w-full md:w-1/2 lg:w-1/4 px-2 mb-3 md:mb-6 lg:mb-14"
          resolver={(arg: any) => {
            let argl = arg as IListingSummary
            return argl.status === "Active" || false
          }}
        />
        <Check
          context={context}
          label="Upcoming Open House"
          inputClassName="bg-transparent"
          param="hasOpenHouse"
          className="uppercase text-composite-text w-full md:w-1/2 lg:w-1/4 px-2 mb-3 md:mb-6 lg:mb-14"
          resolver={(arg: any) => {
            let argl = arg as IListingSummary
            return argl.hasOpenHouse || false
          }}
        />
        <Choose
          className="!hidden w-full md:w-1/2 lg:w-1/4 px-2 mb-3 md:my-6 lg:my-14"
          context={context}
          placeholder="County"
          label="County"
          param="county"
          resolver={arg => arg.address.county}
          match="any"
          reactSelectStyles={selectStyles}
        />
      </>
    )
  }
  /**
   * Standard filters for global Rental entry listing search.
   */
  const rentalEntryFilters = function (context: ISearchContext) {
    return (
      <>
        <Choose
          context={context}
          placeholder="Location"
          label="Location"
          param="address.city"
          resolver={arg => arg.address.city}
          match="one"
          reactSelectStyles={selectStyles}
          options={[
            {
              value: "Aquinnah",
              label: "Aquinnah",
            },
            {
              value: "Chilmark",
              label: "Chilmark",
            },
            {
              value: "Edgartown",
              label: "Edgartown",
            },
            {
              value: "Oak Bluffs",
              label: "Oak Bluffs",
            },
            {
              value: "Vineyard Haven",
              label: "Vineyard Haven",
            },
            {
              value: "West Tisbury",
              label: "West Tisbury",
            },
          ]}
          showAllEntriesOption={true}
        />
        <Choose
          context={context}
          label="Max Price"
          placeholder="Max Price"
          customFilter={(arg, value) => {
            let argl = arg as IListingSummary
            let vstr = value as string
            return (argl.priceLow || 0) <= parseInt(vstr) || false
          }}
          param="maxPrice"
          resolver={arg => arg.priceLow}
          reactSelectStyles={selectStyles}
          options={[
            { label: "< $1k", value: "1000" },
            { label: "< $2k", value: "2000" },
            { label: "< $3k", value: "3000" },
            { label: "< $5k", value: "5000" },
            { label: "< $7k", value: "7000" },
            { label: "< $10k", value: "10000" },
            { label: "< $15k", value: "15000" },
            { label: "< $20k", value: "20000" },
            { label: "< $30k", value: "30000" },
            { label: "< $40k", value: "40000" },
          ]}
        />
        <DateRange
          context={context}
          label="Dates"
          param="dates"
          className=" py-[.4rem] border-b"
          dateInputClassNames="rounded-none bg-transparent uppercase placeholder:text-inherit"
        />
      </>
    )
  }

  const rentalSearchCheckBoxes = function (context: ISearchContext) {
    return (
      <div className="col-span-2 row-span-2 grid grid-col-2 gap-3">
        <label className="col-span-2 text-uppercase">ADDITIONAL FILTERS</label>
        <Check
          context={context}
          label="Air Conditioning"
          inputClassName=" bg-transparent"
          param="air"
          className="text-composite-text self-end"
          resolver={(arg: any) => {
            let argl = arg as IListingSummary
            return argl.features?.find((str) => str == "A/C") !== undefined
          }}
        />
        <Check
          context={context}
          label="Waterfront"
          inputClassName=" bg-transparent"
          param="waterfront"
          className="text-composite-text self-end"
          resolver={(arg: any) => {
            let argl = arg as IListingSummary
            return argl.features?.find((str) => str == "Waterfront") !== undefined
          }}
        />
        <Check
          context={context}
          label="Linens provided"
          inputClassName=" bg-transparent"
          param="linens"
          className="text-composite-text self-end"
          resolver={(arg: any) => {
            let argl = arg as IListingSummary
            return argl.features?.find((str) => str == "Linens Provided") !== undefined
          }}
        />
        <Check
          context={context}
          label="Pool"
          inputClassName=" bg-transparent"
          param="pool"
          className="text-composite-text self-end"
          resolver={(arg: any) => {
            let argl = arg as IListingSummary
            return argl.features?.find((str) => str == "Pool") !== undefined
          }}
        />
      </div>)
  }

  /**
   * Advanced filters for global Rental entry listing search.
   */
  const rentalEntryAdvancedFilters = function (context: ISearchContext) {
    return (
      <>
        <Choose
          context={context}
          placeholder="Location"
          label="Location"
          param="address.city"
          resolver={arg => arg.address.city}
          match="one"
          reactSelectStyles={selectStyles}
          options={[
            {
              value: "Aquinnah",
              label: "Aquinnah",
            },
            {
              value: "Chilmark",
              label: "Chilmark",
            },
            {
              value: "Edgartown",
              label: "Edgartown",
            },
            {
              value: "Oak Bluffs",
              label: "Oak Bluffs",
            },
            {
              value: "Vineyard Haven",
              label: "Vineyard Haven",
            },
            {
              value: "West Tisbury",
              label: "West Tisbury",
            },
          ]}
          className="col-span-2"
          showAllEntriesOption={true}
        />
        {rentalPriceFilters(context)}
        <DateRange
          context={context}
          label="Dates"
          param="dates"
          className=" py-[.4rem] border-b"
          dateInputClassNames="rounded-none bg-transparent uppercase placeholder:text-inherit"
        />
        {rentalSearchCheckBoxes(context)}
      </>
    )
  }

  /**
   * Standard filters for global Rental listing search.
   */
  const rentalStandardFilters = function (context: ISearchContext) {
    return (
      <>
        <Choose
          context={context}
          placeholder="Location"
          label="Location"
          param="address.city"
          resolver={arg => arg.address.city}
          match="any"
          reactSelectStyles={selectStyles}
          showAllEntriesOption={true}
        />
        <DateRange
          context={context}
          label="Dates"
          param="dates"
          className=" py-[.4rem] border-b"
          dateInputClassNames="rounded-none bg-transparent uppercase placeholder:text-inherit"
        />
        <Choose
          context={context}
          placeholder="Features"
          label="Features"
          param="features"
          resolver={arg => arg.features}
          sort="count"
          match="all"
          reactSelectStyles={selectStyles}
        />
        {rentalPriceFilters(context)}
      </>
    )
  }

  const alphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

  const peopleSort = function (context: ISearchContext) {
    return (
      <div className="w-full max-w-screen-lg flex flex-row flex-wrap lg:flex-nowrap justify-center gap-y-2 pt-16 pb-14">
        <span className="uppercase text-xs w-24 text-neutral-500 tracking-widest">
          SEARCH BY LAST NAME
        </span>
        <ul className="flex flex-row flex-wrap lg:flex-nowrap justify-center lg:justify-start gap-y-2">
          {alphabet.map((item, index) => (
            <li key={index} className={`px-2.5 border-b pb-4 ${context.currentFilterValues?.letter == item ? "border-accent border-b-8" : "border-neutral-500"}`}>
              <a onClick={() => { context.setFilterValue!("letter", item) }} key={index} className="text-neutral-500 cursor-pointer">
                {item}
              </a>
            </li>
          ))}
          <li className={`px-2.5 border-b pb-4 ${!context.currentFilterValues?.letter ? "border-accent border-b-8" : "border-neutral-500"}`}>
            <a onClick={() => { context.setFilterValue!("letter", "") }} className="text-neutral-500 cursor-pointer">
              ALL
            </a>
          </li>
        </ul>
      </div>
    )
  }

  const letterFilter = function (context: ISearchContext) {
    const letterOptions = alphabet.map((item) => {
      return {
        label: item,
        value: item
      }
    })
    letterOptions.push({
      label: "All",
      value: ""
    })

    return (
      <Choose
        className="hidden"
        context={context}
        label="Letter"
        placeholder="Letter"
        customFilter={(arg, value) => {
          let argl = arg as IAgent
          let vstr = value as string
          vstr = vstr.toLowerCase()
          return argl.last &&
            argl.last.toLowerCase().startsWith(vstr) ? true : false
        }}
        param="letter"
        resolver={arg => arg.price}
        reactSelectStyles={selectStyles}
        options={letterOptions}
      />
    )
  }

  /**
   * Standard filters for global Real Estate people search.
   */
  const peopleStandardFilters = function (context: ISearchContext) {
    return (
      <>
        <h1 className="sm:col-span-2 lg:col-span-4 text-center">
          {!context.currentFilterValues?.agentFilter ||
            (context.currentFilterValues?.agentFilter == "agents" &&
              "Our Agents")}
          {context.currentFilterValues?.agentFilter == "olympia" &&
            "Olympia Agents"}
          {context.currentFilterValues?.agentFilter == "chehalis" &&
            "Chehalis Agents"}
          {context.currentFilterValues?.agentFilter == "staff" &&
            "Support Team"}
        </h1>
        <div className="bg-body-bg sm:col-span-2 lg:col-span-4 w-full flex flex-wrap md:flex-nowrap gap-y-3 lg:gap-5 justify-center uppercase text-lg mb-7">
          <a
            onClick={() => {
              context.setFilterValue!("agentFilter", "agents")
            }}
            className={`text-body-text cursor-pointer px-5 ${!context.currentFilterValues?.agentFilter ||
              context.currentFilterValues?.agentFilter == "agents"
              ? "border-accent border-b-4"
              : ""
              }`}
          >
            All
          </a>
          <a
            onClick={() => {
              context.setFilterValue!("agentFilter", "olympia")
            }}
            className={`text-body-text cursor-pointer px-5 ${!context.currentFilterValues?.agentFilter ||
              context.currentFilterValues?.agentFilter == "olympia"
              ? "border-accent border-b-4"
              : ""
              }`}
          >
            Olympia
          </a>
          <a
            onClick={() => {
              context.setFilterValue!("agentFilter", "chehalis")
            }}
            className={`text-body-text cursor-pointer px-5 ${!context.currentFilterValues?.agentFilter ||
              context.currentFilterValues?.agentFilter == "chehalis"
              ? "border-accent border-b-4"
              : ""
              }`}
          >
            Chehalis
          </a>
          <a
            onClick={() => {
              context.setFilterValue!("agentFilter", "staff")
            }}
            className={`text-body-text cursor-pointer px-5 ${context.currentFilterValues?.agentFilter == "staff"
              ? "border-accent border-b-4"
              : ""
              }`}
          >
            Support Team
          </a>
        </div>
        <div className="inverted w-full sm:col-span-2 lg:col-span-4 py-6 px-7">
          <Text
            context={context}
            placeholder="SEARCH BY AGENT NAME"
            param="keyword"
            label="SEARCH BY AGENT NAME"
            inputClassName="w-full bg-transparent text-composite-text border-composite-text placeholder:text-inherit"
            resolver={(arg: any, text) => {
              let argl = arg as IAgent
              let match = text.toLowerCase()
              return (argl.first && argl.first.toLowerCase().includes(match)) ||
                (argl.last &&
                  argl.last.toLowerCase().includes(match))
                ? true
                : false
            }}
          />
        </div>
        {letterFilter(context)}
        <Choose
          className="hidden"
          context={context}
          label="Agent Fitler"
          placeholder="Agent Filter"
          customFilter={(arg, value) => {
            let argl = arg as any
            let vstr = value as string
            if (vstr === "staff") {
              return argl.type === "staff"
            }
            else if (vstr === "olympia") {
              return argl.type === "agent" && argl.office?.[0]?.address?.city?.toLowerCase() === "olympia"
            }
            else if (vstr === "chehalis") {
              return argl.type === "agent" && argl.office?.[0]?.address?.city?.toLowerCase() === "chehalis"
            } else {
              return argl.type === "agent"
            }
          }}
          param="agentFilter"
          resolver={arg => arg.price}
          reactSelectStyles={selectStyles}
          options={[
            { label: "agents", value: "agents" },
            { label: "olympia", value: "olympia" },
            { label: "chehalis", value: "chehalis" },
            { label: "staff", value: "staff" },
          ]}
        />
      </>
    )
  }

  const peopleEntrySearchProps = function (context: ISearchContext) {
    return (
      <Text
        context={context}
        placeholder="SEARCH BY AGENT NAME"
        param="keyword"
        label="SEARCH BY AGENT NAME"
        inputClassName="w-full bg-transparent text-composite-text border-composite-text placeholder:text-inherit text-xs"
        className="col-span-4"
        resolver={(arg: any, text) => {
          let argl = arg as IAgent
          let match = text.toLowerCase()
          return (argl.first && argl.first.toLowerCase().includes(match)) ||
            (argl.last &&
              argl.last.toLowerCase().includes(match))
            ? true
            : false
        }}
      />
    )
  }

  /**
   * Sorts to be used in all Rental searches
   */
  const rentalSort = function (context: ISearchContext) {
    return (
      <Sort
        context={context}
        className="text-center py-1"
        label="Sort by: "
        sorts={[
          {
            label: "Price: $$$ to $",
            key: "priceDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (
                (l1.price || l1.priceHigh || 0) - (l0.price || l0.priceHigh || 0)
              )
            },
          },
          {
            label: "Price: $ to $$$",
            key: "priceASC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (
                (l0.price || l0.priceLow || 0) - (l1.price || l1.priceLow || 0)
              )
            },
          },
          {
            label: "Bedrooms: High to Low",
            key: "bedsDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l1.beds || 0) - (l0.beds || 0)
            },
          },
          {
            label: "Bedrooms: Low to High",
            key: "bedsASC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l0.beds || 0) - (l1.beds || 0)
            },
          },
          {
            label: "Sleeps: High to Low",
            key: "sleepsDESC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l1.sleeps || 0) - (l0.sleeps || 0)
            },
          },
          {
            label: "Sleeps: Low to High",
            key: "sleepsASC",
            cb: (arg0, arg1) => {
              let l0 = arg0 as IListingSummary
              let l1 = arg1 as IListingSummary
              return (l0.sleeps || 0) - (l1.sleeps || 0)
            },
          },
        ]}
      />
    )
  }

  const PrevArrow = (props: any) => {
    return (
      <button
        slide-count={props.slideCount}
        current-slide={props.currentSlide}
        {...props}
        className="absolute top-1/2 z-10 cursor-pointer text-white text-6xl -mt-6 drop-shadow left-3 bg-transparent"
      >
        <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle opacity="0.73" cx="31" cy="31" r="30.5" fill="#F5F5F5" fill-opacity="0.8" stroke="#D3D3D3" />
          <path d="M34.875 44.5625L23.25 31L34.875 17.4375" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </button>
    );
  }

  const NextArrow = (props: any) => {
    return (
      <button
        slide-count={props.slideCount}
        current-slide={props.currentSlide}
        {...props}
        className="absolute top-1/2 z-10 cursor-pointer text-white text-6xl drop-shadow -mt-6 inline-block right-3 bg-transparent"
      >
        <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle opacity="0.73" cx="31" cy="31" r="30.5" transform="rotate(180 31 31)" fill="#F5F5F5" fill-opacity="0.8" stroke="#D3D3D3" />
          <path d="M27.125 17.4375L38.75 31L27.125 44.5625" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </button>
    );
  }

  const disclaimer = `
    <p>Based on information submitted to the MLS GRID as of ${data.feedDate.timestamp}. 
    All data is obtained from various sources and may not have been verified by broker or MLS GRID. 
    Supplied Open House Information is subject to change without notice. 
    All information should be independently reviewed and verified for accuracy. 
    Properties may or may not be listed by the office/agent presenting the information.</p>
  `
  const listingSearchPath = data.config.customFields.listingSearchPath

  /**
   * Wrap our pages in the SiteContext to provide configuration and
   * customization of various site elements.  See all usages of useSiteContext()
   * in @organic-return/foundation-gatsby-plugin-agility
   */
  return (
    <SiteContext.Provider
      value={{
        localGetModule: getModule,
        localGetPageTemplate: getPageTemplate,
        listingLayoutProps: {
          vitalsProps: {
            className: "mb-3 uppercase",
            layout: EListingVitalsLayout.column1Border1,
            locationIcon: false,
            twoLineAddress: false,
          },
          formProps: {
            title: "Inquire",
            classNames: "strictTwoColumn property-contact",
            inputTextAreaLabel: "Add a message here...",
            hideReCaptchaText: true,
          },
          similarCardProps: {
            linkText: "View Listing",
            vitalsLayout: EListingVitalsLayout.row1Border0,
          },
          summaryProps: {
            className: "non-exlusive max-w-screen-xl mx-auto p-4 pt-0 flex flex-col [&_.listing-features.whitespace-pre-wrap]:order-first [&_.listing-features]:mx-4 [&_.listing-features:not(.whitespace-pre-wrap)]:items-center [&_.listing-features_img]:!h-8",
            showFeatures: false,
            showCourtesy: false,
          },
          mapProps: { title: "", className: "w-full max-w-screen-xl mx-auto mb-10", },
          detailsProps: {
            title: "PROPERTY DETAILS",
            layout: EListingDetailsLayout.groupNone,
            className:
              "max-w-screen-xl mx-auto px-10 mt-24 mb-10",
          },
          similarCarouselProps: { title: "Similar Properties" },
          rowClassName: "max-w-screen-xl mx-auto listing-description mb-0 [&_.image-gallery]:!mb-0 [&_.listing-courtesy]:!ml-0 [&_.listing-courtesy]:!text-[#666666]",
          showContactUsForm: false,
          displayMediaSection: true,
          headingProps: {
            src: data.config.customFields.listingLayoutHeadingImage?.url,
            className: "h-[150px] md:h-[150px] lg:h-[225px]",
            sizesAndAspect: [
              {
                width: 420,
              },
              {
                media: "min-width:420px",
                width: 640,
              },
              {
                media: "min-width:640px",
                width: 768,
              },
              {
                media: "min-width:768px",
                width: 1024,
              },
              {
                media: "min-width:1024px",
                width: 1280,
              },
              {
                media: "min-width:1280px",
                width: 1440,
              },
              {
                media: "min-width:1440px",
                width: 1920,
              },
            ]
          },
          contactProps: {
            contactCardProps: {
              className: "w-full",
              layout: EContactCardLayout.horizontal,
              showEmail: false,
            }
          },
          displayDisclaimer: true,
          disclaimerLocation: EDisclaimerLocation.afterDetails,
          disclaimers: [{
            mlsSourceName: "Northwest MLS",
            disclaimerText: disclaimer
          }],
          disclaimerCssClasses: "mb-24 max-w-screen-xl mx-auto text-sm px-10",
          displayCourtesyUnderGallery: true
        },
        exclusiveLayoutProps: {
          vitalsProps: {
            composite: true,
            className: "uppercase z-50",
            layout: EListingVitalsLayout.column1Border1,
            locationIcon: false,
          },
          summaryProps: {
            showFeatures: false,
            className: "[&_.listing-features:not(.whitespace-pre-wrap)]:items-center [&_.listing-features_img]:!h-8"
          },
          formProps: {
            title: "",
            classNames: "strictTwoColumn property-contact inverted",
            inputTextAreaLabel: "Add a message here...",
            inputTextAreaClassName: "!border-composite-text !text-body-text-inverted",
          },
          similarCardProps: {
            linkText: "View Listing",
            vitalsLayout: EListingVitalsLayout.row1Border0,
          },
          mapProps: { title: "", className: "w-full max-w-screen-xl mx-auto mb-10" },
          detailsProps: {
            title: "DETAILS",
            layout: EListingDetailsLayout.groupNone,
            className: "max-w-screen-xl mx-auto px-10 mt-24 mb-10",
          },
          carouselProps: {
            slickSettings: {
              nextArrow: <NextArrow />,
              prevArrow: <PrevArrow />
            },
          },
          similarCarouselProps: { title: "Similar Properties" },
          rowClassName: "max-w-screen-xl mx-auto",
          showContactUsForm: true,
          contactUsFormProps: {
            checkboxGroups: {
              label: "I am interested in the following",
              groups: [
                { list: ["Having a broker contact me", "Waterfront"] },

                {
                  list: [
                    "Tumwater",
                    "Lacey",
                    "Olympia",
                  ],
                },
              ],
            },
          },
          contactProps: {
            contactCardProps: {
              layout: EContactCardLayout.horizontal,
              showEmail: false,
            }
          },
          displayDisclaimer: true,
          disclaimerLocation: EDisclaimerLocation.afterDetails,
          disclaimers: [{
            mlsSourceName: "Northwest MLS",
            disclaimerText: disclaimer
          }],
          disclaimerCssClasses: "mb-24 max-w-screen-xl mx-auto text-sm px-10"
        },
        rentalLayoutProps: {
          vitalsProps: {
            className: "mb-3 inverted",
          },
          carouselProps: {
            className: "center",
            centerMode: true,
            infinite: true,
            centerPadding: "60px",
            slidesToShow: 3,
          },
          detailsProps: {
            title: "Full Details",
            className: "max-w-screen-xl mx-auto",
            layout: EListingDetailsLayout.groupColumns,
          },
          similarCarouselProps: {
            title: "Similar Rentals",
          },
          summaryProps: {
            className: "max-w-screen-xl mx-auto p-4",
            showFeatures: false,
          },
          rowClassName: "max-w-screen-xl mx-auto",
          mapProps: { title: "", className: "my-10" },
          formProps: {
            classNames: "inverted strictTwoColumn property-contact",
            title: "Request Information",
            inputTextAreaLabel: "Add a message here...",
          },
          showContactUsForm: true,
          contactUsFormProps: {
            checkboxGroups: {
              label: "I am interested in the following",
              groups: [
                { list: ["Having a broker contact me", "Vacation rentals"] },
                {
                  list: [
                    "Edgartown",
                    "Oak Bluffs",
                    "West Tisbury",
                    "Chilmark",
                    "Vineyard Haven",
                    "Aquinnah",
                  ],
                },
              ],
            },
          },
          calendarProps: {
            displayRates: true
          }
        },
        listingSearchProps: {
          primaryFilters: listingStandardFilters,
          alternateFilters: listingMiddleFilters,
          sort: listingSort,
          hideAlternateFiters: true,

          filterClassName: "text-white py-6 bg-body-bg",
          headerClassName: "max-w-screen-xl mx-auto flex-wrap",
          pagerClassName: "max-w-screen-xl mx-auto",
          mapClassName: "w-full",
          resultsClassName: "max-w-screen-xl mx-auto",
          className: "listing-search-module",
          showCount: false,
          headerChildren: (
            <div className="w-full flex justify-center text-lg font-bold">
              <Count />
            </div>
          ),
          defaultSort: "activeDESC",
        },
        listingEntrySearchProps: {
          standardFilters: listingEntryFilters,
          className: "w-full max-w-screen-lg mx-auto",
          buttonClassName: "border border-white hover:bg-button-bg-inverted font-bold text-xs uppercase",
          titleClassName: "text-composite-text text-3xl font-light tracking-widest pb-6 whitespace-pre-line",
          controlsBoxClassName: "relative top-24",
          onSubmit: async (filters) => {
            const params = new URLSearchParams({
              filters: JSON.stringify(filters),
            })
            await navigate(
              listingSearchPath + "#" + params.toString() + "&sort=\"activeDESC\"&showMap=true"
            )
          }
        },
        communitySearchProps: {
          primaryFilters: communityStandardFilters,
          secondaryFilters: listingMiddleFilters,
          filterClassName: "inverted",
          headerClassName: "max-w-screen-xl mx-auto",
          pagerClassName: "max-w-screen-xl mx-auto",
          resultsClassName: "max-w-screen-2xl mx-auto",
          mapClassName: "w-99",
          sort: listingSort,
        },
        rentalSearchProps: {
          sort: rentalSort,
          primaryFilters: rentalStandardFilters,
          secondaryFilters: rentalSearchCheckBoxes,
          filterClassName: "inverted text-white",
          headerClassName: "max-w-screen-xl mx-auto",
          pagerClassName: "max-w-screen-xl mx-auto",
          mapClassName: "w-99",
          resultsClassName: "max-w-screen-2xl mx-auto",
        },
        peopleSearchProps: {
          primaryFilters: peopleStandardFilters,
          sort: peopleSort,
          initialFilter: `{"agentFilter":"agents"}`,
          headerClassName: "max-w-screen-lg mx-auto flex-wrap",
          pagerClassName: "max-w-screen-lg mx-auto",
          resultsClassName: "max-w-screen-lg mx-auto pb-24",
          filterClassName: "max-w-screen-lg mx-auto",
          showCount: false,
          pageSize: 12,
          pagerBottom: true,
        },
        globalPeopleSearchCardProps: {
          layout: EContactCardLayout.vertical,
          showEmail: false,
          showSocial: false,
          showWorkTitle: true,
        },
        rentalEntrySearchProps: {
          standardFilters: rentalEntryFilters,
          buttonClassName: "border border-white hover:bg-button-bg-inverted",
        },
        rentalEntryAdvancedSearchProps: {
          standardFilters: rentalEntryAdvancedFilters,
          buttonClassName: "border border-white hover:bg-button-bg-inverted",
          onSubmit: async (filters: any) => {
            let featuresFilter: string[] = []
            filters["air"] && featuresFilter.push("A/C")
            filters["waterfront"] && featuresFilter.push("Waterfront")
            filters["linens"] && featuresFilter.push("Linens Provided")
            filters["pool"] && featuresFilter.push("Pool")

            if (featuresFilter.length > 0) {
              filters = {
                ...filters,
                features: featuresFilter,
              }
            }

            const params = new URLSearchParams({
              filters: JSON.stringify(filters),
            })
            await navigate(
              data.config.customFields.rentalSearchPath +
              "#" +
              params.toString()
            )
          },
        },
        globalContactFormProps: {
          layout: EContactFormLayout.column2static,
          classNames: "!max-w-screen-md lg:!max-w-screen-xl mx-auto px-5 py-16",
          fieldsetClassName: "!bg-body-bg !text-body-text !max-w-none lg:px-0 md:!flex-wrap lg:flex-nowrap text-2xl lg:items-center",
          mainLegendClassName: "!py-4 lg:w-1/2 !normal-case lg:pr-20 !text-6xl relative lg:top-40",
          mainListClassName: "relative md:grid-cols-2 w-full lg:w-1/2 !my-0 lg:!-mt-28 lg:pl-20 text-xs !gap-y-6",
          submitButtonClassName: "!bg-transparent !text-body-text !border-solid !px-5 !w-auto",
          inputTextClassNames: "!border-0 !border-b !leading-6 !px-0",
          inputTextAreaLabel: "MESSAGE...",
          radiobtnGroupLabelClassNames: "inline-block !text-body-text mr-1 !mb-0",
          radiobtnGroupWrapperClassNames: "!inline-flex relative float-right",
          radiobtnListClassName: "!grid-rows-none !grid-cols-2 !grid-flow-col mt-0.5",
          radiobtnGroup: {
            label: "Are you working with a Greene Realty agent already?",
            list: ["Yes", "No"],
          },
          inputSelect: {
            label: "I'M WORKING WITH",
            list: allAgents
          },
          hideReCaptchaText: true,
        },
        globalSellFormProps: {
          layout: ESellFormLayout.row2map,
          propertyTypes: [
            {
              type: "Residential",
              subtypes: [
                "Single Family Residence",
                "Stock Cooperative",
                "Condominium",
                "Apartment",
                "Townhouse",
                "Mobile Home",
                "Mixed Use",
                "Office",
              ],
            },
            {
              type: "Rental",
              subtypes: [
                "Apartment",
                "Single Family Residence",
                "Condominium",
                "Multi Family",
                "Stock Cooperative",
                "Duplex",
                "Townhouse",
                "Mixed Use",
                "Residential",
                "Mobile Home",
                "Triplex",
                "House (detached)",
                "Office",
                "Condotel",
              ],
            },
            {
              type: "Detached Home",
              subtypes: [],
            },
            {
              type: "Multi-family",
              subtypes: [
                "Multi Family",
                "Duplex",
                "Triplex",
                "Townhouse",
              ],
            },
            {
              type: "Commercial",
              subtypes: [
                "Mixed Use",
                "Office",
                "Retail",
                "Industrial",
                "Warehouse",
                "Apartment",
                "Townhouse",
              ],
            },
            {
              type: "Lots & Land",
              subtypes: ["Unimproved Land"]
            },
            {
              type: "Condo",
              subtypes: [],
            },
            {
              type: "Business Opportunity",
              subtypes: [
                "Retail",
                "Mixed Use",
                "Industrial",
                "Office",
                "Apartment",
                "Warehouse",
              ]
            },
            {
              type: "Co-op",
              subtypes: [],
            },
            {
              type: "Multi-family Townhouse",
              subtypes: [],
            },
            {
              type: "Single-family Townhouse",
              subtypes: [],
            },
            {
              type: "Land",
              subtypes: [],
            },
            {
              type: "House (detached)",
              subtypes: [],
            },
            {
              type: "Vacant Land",
              subtypes: [],
            },
            {
              type: "Condop",
              subtypes: [],
            },
            {
              type: "Commercial Building",
              subtypes: [],
            },
            {
              type: "House (attached)",
              subtypes: [],
            },
            {
              type: "House W/accessory",
              subtypes: [],
            },
          ],
          propertyTypeSelectLabel: "Property Type",
          propertySubtypeSelectLabel: "Sub-Type",
          bedsSelect: {
            placeholder: "Beds",
            selectOptions: [
              {
                label: "1",
                value: "1",
              },
              {
                label: "2",
                value: "2",
              },
              {
                label: "3",
                value: "3",
              },
              {
                label: "4",
                value: "4",
              },
              {
                label: "5",
                value: "5",
              },
              {
                label: "6",
                value: "6",
              },
              {
                label: "7+",
                value: "7+",
              },
            ]
          },
          bathsSelect: {
            placeholder: "Baths",
            selectOptions: [
              {
                label: "1",
                value: "1",
              },
              {
                label: "2",
                value: "2",
              },
              {
                label: "3",
                value: "3",
              },
              {
                label: "4",
                value: "4",
              },
              {
                label: "5",
                value: "5",
              },
              {
                label: "6",
                value: "6",
              },
              {
                label: "7+",
                value: "7+",
              },
            ]
          },
          squareFootageSelect: {
            placeholder: "Sq. Ft.",
            selectOptions: [
              {
                label: "Under 1000 ft²",
                value: "Under 1000 ft²",
              },
              {
                label: "1000-2000 ft²",
                value: "1000-2000 ft²",
              },
              {
                label: "2000-3000 ft²",
                value: "2000-3000 ft²",
              },
              {
                label: "3000-4000 ft²",
                value: "3000-4000 ft²",
              },
              {
                label: "4000-5000 ft²",
                value: "4000-5000 ft²",
              },
              {
                label: "5000-6000 ft²",
                value: "5000-6000 ft²",
              },
              {
                label: "6000-7000 ft²",
                value: "6000-7000 ft²",
              },
              {
                label: "7000-8000 ft²",
                value: "7000-8000 ft²",
              },
              {
                label: "Over 8000 ft²",
                value: "Over 8000 ft²",
              },
            ]
          },
          conditionSelect: {
            placeholder: "Condition",
            selectOptions: [
              {
                label: "Excelent",
                value: "Excelent",
              },
              {
                label: "Good",
                value: "Good",
              },
              {
                label: "Average",
                value: "Average",
              },
              {
                label: "Fair",
                value: "Fair",
              },
              {
                label: "Poor",
                value: "Poor",
              },
            ],
          },
          inputTextAreaLabel: "Additional information...",
        },
        globalListingCarouselProps: {
          slickSettings: {
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
            dots: true,
            appendDots: (dots: any) => {
              let currentIndex = dots.findIndex(dot => dot.props.className == "slick-active")
              return (
                <div>
                  <div className="flex flex-row justify-center items-center gap-2 text-sm">
                    <span className="block">{`${currentIndex + 1}`.padStart(2, "0")}</span>
                    <ul className="flex flex-row justify-center items-center">
                      {dots}
                    </ul>
                    <span className="block">{`${dots.length}`.padStart(2, "0")}</span>
                  </div>
                </div>
              )
            },
          },
        },
        globalListingCarouselCardProps: {
          vitalsTwoLineAddress: true,
          vitalsLocationIcon: false,
        },
        globalListingCardProps: {
          vitalsTwoLineAddress: false,
          vitalsLocationIcon: false,
          vitalsLayout: EListingVitalsLayout.column1Border0,
          vitalsProps: {
            omitAddressParts: undefined
          },
          vitalsClassName: "after:content-['Courtesy_of_NWMLS'] after:block  after:pt-2.5 after:text-xs"
        },
        agentLayoutProps: {
          showCarousel: false,
          contactCardProps: {
            layout: EContactCardLayout.twoColsAndForm,
            showBio: true,
            contactUnderPhoto: true,
            showOffices: false,
            showEmail: false,
            agentInfoClassName: "!text-body-text",
          },
          showRequestInfoForm: false,
          listingsTabsProps: {
            activeListingsTabTitle: "ACTIVE",
            soldListingsTabTitle: "SOLD",
            activeTabClass: "border-b-8 border-accent text-body-text font-bold w-auto",
            defaultTabClass: "text-[#6c6c6c]",
          },
          testimonialsProps: {
            title: "TESTIMONIALS",
            headingClass: "w-full max-w-screen-lg mx-auto text-[36px] pb-12 font-light tracking-widest",
            showQuotes: false,
            columnsWrapperClass: "w-full max-w-screen-lg mx-auto flex flex-col md:flex-row items-center",
            leftColumnClass: "hidden",
            rightColumnClass: "px-0 w-full flex justify-center flex-wrap items-start",
            dividerClass: "hidden",
            showAttribution: true,
            slickSettings: {
              nextArrow: <NextArrow />,
              prevArrow: <PrevArrow />,
              arrows: true,
              dots: true,
              appendDots: (dots: any) => {
                let currentIndex = dots.findIndex(dot => dot.props.className == "slick-active")
                return (
                  <div>
                    <div className="flex flex-row justify-center items-center gap-2 text-sm">
                      <span className="block">{`${currentIndex + 1}`.padStart(2, "0")}</span>
                      <ul className="flex flex-row justify-center items-center">
                        {dots}
                      </ul>
                      <span className="block">{`${dots.length}`.padStart(2, "0")}</span>
                    </div>
                  </div>
                )
              },
            }
          },
          carouselListingCardVitalsLayout: EListingVitalsLayout.column1Border1
        },
        locationSearchProps: {
          bounds: [18.132325188280454, -161.84459944493463, 23.35944708522449, -152.90172869005667]
        },
        footerLayout: FooterLabeledColumns,
        footerProps: {
          showAttribution: true,
          copyright: data.config.customFields.footerCopyright,
          colsTitles: data.config.columnsTitles,
          formProps: {
            classNames: "strictTwoColumn",
            fieldsetClassName: "!p-0",
            mainLegendClassName: "hidden",
            mainListClassName: "!my-0",
            inputTextAreaLabel: "Message...",
            inputTextAreaClassName: "!text-body-text",
            inputTextAreaRequired: true,
            children: (<p className="md:w-72 max-w-full py-2.5">
              Receive home DIY projects, Thurston County market updates, local community stories and more!
            </p>),
          },
          htmlCols: [
            `
            <dl class="flex flex-col">
              <dt class="text-sm">Office</dt>
              <dd class="text-lg">Olympia:&nbsp;${data.offices?.nodes?.[0].phone}</dd>
              <dd class="text-lg mb-4">Chehalis:&nbsp;${data.offices?.nodes?.[1]?.phone?.replaceAll('-', '.')}</dd>
              <dt class="text-sm">Email</dt>
              <dd class="text-lg mb-4">${data.offices?.nodes?.[0].email}</dd>
              <dt class="text-sm">Address</dt>
              <dd class="text-lg mb-2">
                <span class="text-base">Olympia Office:</span><br/>
                ${data.offices?.nodes?.[0].address.street1} ${data.offices?.nodes?.[0].address.street2 ?? ""}<br />
                ${data.offices?.nodes?.[0].address.city} ${data.offices?.nodes?.[0].address.state} ${data.offices?.nodes?.[0].address.postal}
              </dd>
              <dd class="text-lg mb-4">
                <span class="text-base">Chehalis Office:</span><br/>
                ${data.offices?.nodes?.[1].address.street1} ${data.offices?.nodes?.[1].address.street2 ?? ""}<br />
                ${data.offices?.nodes?.[1].address.city} ${data.offices?.nodes?.[1].address.state} ${data.offices?.nodes?.[1].address.postal}
              </dd>
            </dl>
            `
          ]
        },
        communityPageLayout: CommunityLayoutHeroBox,
        communityLayoutProps: {
          listingsResultPageSize: 6,
          requestInfoFormProps: {
            mainLegendClassName:
              "!pt-2 !font-normal !text-h1-text !text-[42px]",
            submitButtonClassName:
              "!text-body-text-inverted !uppercase !border-0 !max-w-none !w-full md:!w-1/2",
            inputTextAreaClassName: "!h-32 border-h1-text",
            inputTextClassNames: "border-h1-text",
            hideReCaptchaText: true,
          },
          marketStatsProps: {
            title: "Residential Market Stats",
          },
        },
        peopleEntrySearchProps: {
          standardFilters: peopleEntrySearchProps,
          titleClassName: "text-composite-text text-3xl font-light tracking-widest pb-6",
          buttonClassName: "uppercase text-xs font-bold"
        },
        headerProps: {
          gradient: false,
          className: "inverted md:!relative"
        },
        videoPageProps: {
          displayAgentCard: false,
          displayContactFormModule: true,
          contactFormModuleCustomFields: {
            title: "Curious of your home’s value?",
            text: "<p>Get a free market analysis!</p>",
            choiceControlText: "Are you working with a Olympic Sotheby's International Realty agent?"
          }
        }
      }}
    >
      {children}
    </SiteContext.Provider>
  )
}